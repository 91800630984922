@import "../../_variables.scss";

.woman {
    height: 642px;
    width: 100%;
    background: radial-gradient(circle, $backcolorwomen 0%, $backcolorwomen2 100%);

    &--slogan {
      box-sizing: content-box;
      height: calc(100% - 100px);
      color: #FFFFFF;
      font-family: $fontfortitles;
      font-size: 36px;
      font-weight: bold;
      left: 15vw;
      padding-left: 22vw;
      padding-top: 212px;
      letter-spacing: -1.39px;
      line-height: 47px;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 48vw;
      max-width: 43%;
      justify-content: center;
      text-align: center;

      &--store-button {
        padding-top: 10px;
        left: 4vw;
        width: 100px;
        text-align: center;
        position: relative;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &::before {
      background-repeat: no-repeat;
      background-position: left top;
      background-size: cover;
      content: "";
      height: 104%;
      position: absolute;
      top: 0;
      width: 100%;
      will-change: transform;
      z-index: -1;
      opacity: 0.15;
    }
}
.logo-white--img {
  padding-top: 38px;
  padding-left: 42vw;
  position: absolute;
}
.woman--phone-hand {
  position: absolute;
  top: calc(26% + 5px);
  z-index: 4;
}
.woman--phone-hand--img {
  max-width: 34vw;
}

//-----------------------------------------------------------------------------------------
.woman-middle {
  background-color: #FFFFFF;
  top: 642px;
  height: 1280px;
  width: 100%;
  position: absolute;

  &--slogan-header01 {
    position: relative;
    top: 208px;
    padding-left: 6vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    color: $fontbasecolor;
    font-family: $fontfortitles;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 40px;
  }

  &--slogan-sentences01 {
    position: relative;
    box-sizing: content-box;
    color: #464445;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 48vw;
    max-width: 460px;
  }

  &--slogan-header02 {
    position: relative;
    top: 520px;
    left: 45vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    color: $fontbasecolor;
    font-family: $fontfortitles;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 40px;
  }

  &--slogan-sentences02 {
    position: relative;
    box-sizing: content-box;
    color: #464445;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 46vw;
    max-width: 480px;
  }

  &--download {
    position: relative;
    top: 766px;
    height: 47px;
    color: $fontbasecolor;
    font-family: $fontfortitles;
    font-size: 36px;
    text-align: center;
    box-sizing: content-box;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 350px;
    margin-left: auto;
    margin-right: auto;

    &--button {
      padding-left: 80px;
    }
  }
}
.woman--match-bubbles--img {
  top: -210px;
  padding-left: 48vw;
  position: relative;
  max-width: 42vw;
}
.woman--money-phone--img {
  top: -220px;
  left: -50vw;
  padding-left: 10vw;
  position: relative;
  max-width: 28vw;
}

//-----------------------------------------------------------------------------------------
.woman-bottom {
  position: relative;
  height: 1320px;
  width: 100%;
  top: 1460px;
  background: radial-gradient(circle, $backcolorwomen 0%, $backcolorwomen2 100%);

  &--sales-pitch {
    position: relative;
    color: #ffffff;
    top: 120px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: content-box;
    height: 108px;
    font-family: $fontfortitles;
    font-size: 36px;
    text-align: center;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 510px;
  }

  &--sales-header01 {
    position: relative;
    color: #ffffff;
    top: 330px;
    padding-left: 10vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    font-family: $fontfortitles;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    max-width: 540px;
  }

  &--sales-sentences01 {
    position: relative;
    color: #ffffff;
    box-sizing: content-box;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 46vw;
    max-width: 460px;
    padding-top: 10px;
  }

  &--sales-header02 {
    position: relative;
    color: #ffffff;
    top: 670px;
    left: 50vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    font-family: $fontfortitles;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    max-width: 460px;
  }

  &--sales-sentences02 {
    position: relative;
    color: #ffffff;
    box-sizing: content-box;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 48vw;
    max-width: 460px;
    padding-top: 10px;
  }
}
.woman--match-sample--img {
  top: -240px;
  padding-left: 47vw;
  position: relative;
  max-width: 40vw;
}
.woman-bottom-phone--img {
  top: 480px;
  left: 10vw;
  position: relative;
  max-width: 30vw;
  z-index: +1;
}
//-----------------------------------------------------------------------------------------
.woman-footer {
  background-color: #FFFFFF;
  top: 3082px;
  height: 500px;
  width: 100%;
  position: absolute;
  

  &--storeinfo {
    position: relative;
    top: 180px;
    height: 47px;
    color: $fontbasecolor;
    font-family: $fontfortitles;
    font-size: 36px;
    text-align: center;
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 98%;

    &--button {
      position: relative;
      padding-top: 32px;
      padding-left: 36vw;
      justify-content: center;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

/* start of phone styles */
//===============================================================================================
@media screen and (max-width: $mobilewidth) {
  .woman {
    height: 800px;
    display: block;
    padding-left: 0px;
  }
  .logo-white--img {
    padding-left: 0px;
    padding-top: 25px;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .woman--slogan {
    width: auto;
    top: 30px;
    padding-left: 0px;
    left: 0px;
    padding-top: 30px;
    display: block;
    font-size: 19px;
    text-align: center;
    letter-spacing: -1.18px;
    line-height: 34px;
    max-width: 80%;
  }
  .woman--slogan--store-button {
    top: 40px;
    left: 0vw;
    width: 200px;
  }
  .woman--phone-hand {
    height: auto; 
    width: auto; 
    max-width: 300px;
    max-height: 300px;
    z-index: 4;
    position: relative;
    top: 100px;
    right: 25vw;
  }
  .woman--phone-hand--img {
    max-width: 200vw;
    width: 128%;
  }
//------------------------------------------------------------------------------------------------
  .woman-middle {
    top: 800px;
    height: 1500px;
  }
  .woman-middle--slogan-header01 {
    position: relative;
    padding-left: 0px;
    top: 140px;
    left: 0px;
    font-size: 24px;
    line-height: 34px;
    max-width: 400px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
  .woman-middle--slogan-sentences01 {
    top: 20px;
    font-size: 18px;
    line-height: 27px;
    max-width: 400px;
    max-height: 300px;
    width: 80vw;
  }
  .woman--match-bubbles--img {
    top: 40px;
    padding-left: 0px;
    left: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto; 
    width: auto; 
    max-width: 300px;
    max-height: 300px;
    max-width: 84vw;
  }
  .woman-middle--slogan-header02 {
    position: relative;
    top: 610px;
    left: 0px;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
  .woman-middle--slogan-sentences02 {
    top: 20px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw;
  }
  .woman--money-phone--img {
    top: 40px;
    left: 0px;
    padding-left: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50vw;
    max-height: auto;
  }
  .woman-middle--download {
    top: 1070px;
    padding-left: 0vw;
    left: -2vw;
    font-size: 24px;
    line-height: 47px;
  }
  .woman-middle--download--button {
    padding-left: 80px;
    left: 0vw;
    width: 200px;
  }
//------------------------------------------------------------------------------------------------
  .woman-bottom {
    //height: 1240px;
    top: 1700px;
    height: 1580px;
    width: auto;
  }
  .woman-bottom--sales-pitch {
    top: 60px;
    left: 5px;
    font-size: 24px;
    line-height: 32px;
    width: 290px;
  }
  .woman-bottom--sales-header01 {
    top: 160px;
    left: 0vw;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
  .woman-bottom--sales-sentences01 {
    top: 20px;
    left: 0px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw;
  }
  .woman--match-sample--img {
    top: 50px;
    left: -30px;
    padding-left: 0vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: auto;
    max-width: 64vw;
    max-height: auto;
  }
  .woman-bottom--sales-header02 {
    padding-top: 65px;
    position: relative;
    top: 640px;
    left: 2vw;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
  .woman-bottom--sales-sentences02 {
    top: 20px;
    left: 0px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw;
  }
  .woman-bottom-phone--img {
    padding-top: 390px;
    left: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50vw;
  }
//------------------------------------------------------------------------------------------------
  .woman-footer {
    top: 3762px;
    height: 500px;
    z-index: -1;
  }
  .woman-footer--storeinfo {
    padding-top: 70px;
    left: 1vw;
    font-size: 24px;
    line-height: 34px;
    width: 290px;
  }
  .woman-footer--storeinfo--button {
    top: -20px;
    width: 200px;
    margin-left: -28vw;
  }
}