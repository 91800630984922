.store-button--logo {
    font-size: 25px;
    margin-right: 6px;
    display: inline-block;
    top: 2px;
    position: relative;
}

.store-button {
  //border: 3px dotted red;
  border-radius: 5px;
  position: relative;
  top: 10px;
  width: 200px;
  padding: 5px 0px;

  background-color: #ee286a;

  a {
    color: #fff;
    font-size: 18px;
    text-decoration: none;
  }
  /* display: inline; */
}