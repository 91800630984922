
.App {
  font-family: sans-serif;
  text-align: center;
  margin: 0 0;
  padding: 0px;
}

html {
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700");
@import url("https://fonts.googleapis.com/css?family=PT%20Serif:400,600,700");
