
// @import "../../stylePage.scss";

@import "../../_variables.scss";

// $fontbasecolor: #0b0f72;
// $elephants_photo: "https://images.unsplash.com/photo-1517486430290-35657bdcef51?ixlib=rb-1.2.1&dpr=1&auto=format&fit=crop&w=1000&q=80&cs=tinysrgb";

.tos_p {
    background-color: #e9e5e5ef;
    position: relative;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    top: calc(100% - 100px);
    padding-left: 33%;
  
    &--links {
      font-size: 10px;
      font-weight: bold;
      flex-direction: row;
      float: left;
      color: $fontfootercolor;
      font-family: $fontfortitles;
      min-width: 50vw;
      justify-content: center;
      padding: 10px;
      &--centered {
        margin: 10px auto;
      }
      /* unvisited link */
      a:link, a:visited, a:active {
        color: $fontfootercolor;
        text-decoration: underline;
      }
      /* mouse over link */
      a:hover {
        color: hotpink;
        text-decoration: none;
      }
    }
   
    // set background gradient colors
    &::before {  
      background-repeat: no-repeat;
      background-position: left top;
      background-size: cover;
      content: "";
      height: 104%;
      // left: 10%;
      // position: fixed;
      position: absolute;
      top: 0;
      width: 100%;
      // width: 80%;
      will-change: transform;
      z-index: -1;
      // background-color: #e9e5e5ef;
      // background-color: #e9e5e5ef;
      // background-color: #2e0a0a;
      opacity: 0.15;
    }
}


@media screen and (max-width: $mobilewidth) {
  /* start of phone styles */
  .tos_p {
    height: auto;
  }

  .tos_p--links {
    width: auto;
    display:block;
  }

}


// @media screen and (max-width: 400px) {}
