@import "../../_variables.scss";

.ToSmain {
  background-color: #FFFFFF;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 30px;
  padding-bottom: 60px;
  font-family: "bebas";
  display: "block";
  font-size: 14px;
  color: #333;
  background-color: "white";
  word-break: "break-word";
  justify-content: left; 

  h1 {
    font-size: 36px;
    font-weight: bold;
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
  }

  h3 {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
  }
  
  &--text {
    font-family: "courier new";
    line-height: 16px;
    word-spacing: 0.1em;

    &-sub {
      margin-left: 10px;
      word-spacing: 0.1em;
      margin-right: 20px;
    }

    &-boldall {
      font-weight: bold;
      word-spacing: 0.1em;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    margin-left: 10px;
    margin-top: 8px;
    margin-bottom: 0px;
    word-spacing: 0.1em;
  }

  &--indexlinks {
    font-size: 14px;
    word-spacing: 0.1em;
    font-weight: bold;

    /* unvisited link */
    a:link, a:visited, a:active {
      color: blue;
      text-decoration: underline;
    }
    /* mouse over link */
    a:hover {
      color: hotpink;
      text-decoration: none;
    }
  }

  &--linebreak {
    line-height: 36px;
    font-weight: bold;
    font-family: "courier new";

    hr {
      color: #333; 
      background-color: #333;
      height: 5px;
    }
  }
}

.logo-black {
  &--img {
    max-width:15%;
    height:auto;
  }
}


@media screen and (max-width: $mobilewidth) {

}