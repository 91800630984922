@import "../../_variables.scss";

.keith {
  &--store-button {
    position: relative;
    display: block;
  }
}

.keith-ad-image {
  position: relative;
}

.keith-ad-button {
  top: 309px;
  left: 224px;
  position: absolute;
}