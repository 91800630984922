
@import "./../../_variables.scss";

.landergen {
  height: 642px;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: relative;

  &--slogan {
    box-sizing: content-box;
    height: calc(100% - 100px);
    color: $fontbasecolor;
    font-family: $fontfortitles;
    font-size: 36px;
    font-weight: bold;
    left: 15vw;
    padding-left: 32vw;
    padding-top: 130px;
    letter-spacing: -1.39px;
    line-height: 47px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 80vw;
    max-width: 60vw;
    justify-content: left;
    text-align: left;

    &--store-button {
      padding-top: 10px;
      padding-left: 36vw;
      width: 100px;
      text-align: center;
      position: relative;
    }
  }

  &::before {
    background: linear-gradient(
        102.76deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 50%
      ),
    url($header_romantic_photo);
    content: "";
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    will-change: transform;
    z-index: -1;
    width: 100%;
    height: 104%;
    opacity: 0.15;
  }
}
.landergen--logo-color--img {
  padding-top: 38px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 22vw;
}
.landergen--phone-hand {
  position: absolute;
  top: calc(26% + 5px);  
  z-index: 4;
}
.landergen--phone-hand--img {
  max-width: 34vw;
}

//-----------------------------------------------------------------------------------------
.landergen-middle {
  background: radial-gradient(circle, $backcolorwomen 0%, $backcolorwomen2 100%);
  top: 642px;
  height: 1280px;
  width: 100%;
  position: absolute;

  &--slogan-header01 {
    position: relative;
    top: 208px;
    padding-left: 6vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    color: #FFFFFF;
    font-family: $fontfortitles;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 40px;
  }

  &--slogan-sentences01 {
    position: relative;
    box-sizing: content-box;
    color: #FFFFFF;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 48vw;
    max-width: 460px;
    padding-top: 10px;
  }

  &--slogan-header02 {
    position: relative;
    top: 520px;
    left: 5vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    color: #FFFFFF;
    font-family: $fontfortitles;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 40px;
  }

  &--slogan-sentences02 {
    padding-top: 10px;
    position: relative;
    box-sizing: content-box;
    color: #FFFFFF;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 46vw;
    max-width: 480px;
  }

  &--download {
    position: relative;
    top: 766px;
    height: 47px;
    color: #ffffff;
    font-family: $fontfortitles;
    font-size: 36px;
    text-align: center;
    box-sizing: content-box;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 350px;
    margin-left: auto;
    margin-right: auto;

    &--button {
      padding-left: 80px;
    }
  }
}
.landergen--female-circle--img {
  top: -320px;
  padding-left: 48vw;
  position: relative;
  max-width: 40vw;
}
.landergen--match-sample--img {
  top: -220px;
  left: 40vw;
  padding-left: 10vw;
  position: relative;
  width: 40vw;
  max-width: 400px;
}


//-----------------------------------------------------------------------------------------
.landergen-bottom {
  position: relative;
  height: 1320px;
  width: 100%;
  top: 1460px;
  background: radial-gradient(circle, $backcolormen 0%, $backcolormen2 100%);

  &--sales-pitch {
    position: relative;
    color: #ffffff;
    top: 120px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: content-box;
    height: 108px;
    font-family: $fontfortitles;
    font-size: 36px;
    text-align: center;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 92vw;
  }

  &--sales-header01 {
    position: relative;
    color: #ffffff;
    top: 330px;
    padding-left: 5vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    font-family: $fontfortitles;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    max-width: 540px;
  }

  &--sales-sentences01 {
    position: relative;
    color: #ffffff;
    box-sizing: content-box;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 46vw;
    max-width: 460px;
    padding-top: 10px;
  }

  &--sales-header02 {
    position: relative;
    color: #ffffff;
    top: 670px;
    padding-left: 5vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    font-family: $fontfortitles;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    max-width: 460px;
  }

  &--sales-sentences02 {
    position: relative;
    color: #ffffff;
    box-sizing: content-box;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 48vw;
    max-width: 460px;
    padding-top: 10px;
  }
}
.landergen--refined-searches--img {
  top: -280px;
  left: 40vw;
  padding-left: 10vw;
  position: relative;
  max-width: 38vw;
}
.landergen--bottom-phone--img {
  top: 420px;
  left: 60vw;
  position: relative;
  max-width: 30vw;
  z-index: +1;
}
//-----------------------------------------------------------------------------------------
.landergen-footer {
  background-color: #FFFFFF;
  top: 3082px;
  height: 500px;
  width: 100%;
  position: absolute;
  

  &--storeinfo {
    position: relative;
    top: 180px;
    height: 47px;
    color: $fontbasecolor;
    font-family: $fontfortitles;
    font-size: 36px;
    text-align: center;
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 98%;

    &--button {
      position: relative;
      padding-top: 32px;
      padding-left: 36vw;
      justify-content: center;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &::before {
    background: linear-gradient(
        102.76deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 50%
      ),
    url($footer_smile_woman);
    content: "";
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    will-change: transform;
    z-index: -1;
    width: 100%;
    height: 104%;
    opacity: 0.15;
  }
}

/* start of phone styles */
//===============================================================================================
@media screen and (max-width: $mobilewidth) {
  .landergen {
    height: 800px;
    display: block;
    padding-left: 0px;
  }
  .landerger--logo-color--img {
    padding-left: 0px;
    padding-top: 25px;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 50vw;
  }
  .landergen--slogan {
    width: auto;
    top: 30px;
    padding-left: 0px;
    left: 0px;
    padding-top: 30px;
    display: block;
    font-size: 19px;
    text-align: center;
    letter-spacing: -1.18px;
    line-height: 34px;
    max-width: 80%;
  }
  .landergen--slogan--store-button {
    top: 40px;
    right: 35vw;
    width: 200px;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .landergen--phone-hand {
    height: auto; 
    width: auto; 
    max-width: 300px;
    max-height: 300px;
    z-index: 4;
    position: relative;
    top: 100px;
    right: 25vw;
  }
  .landergen--phone-hand--img {
    max-width: 200vw;
    width: 128%;
  }
//------------------------------------------------------------------------------------------------
  .landergen-middle {
    top: 800px;
    height: 1500px;
  }
  .landergen-middle--slogan-header01 {
    position: relative;
    padding-left: 0px;
    top: 140px;
    left: 0px;
    font-size: 24px;
    line-height: 34px;
    max-width: 400px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
  .landergen-middle--slogan-sentences01 {
    top: 20px;
    font-size: 18px;
    line-height: 27px;
    max-width: 400px;
    max-height: 300px;
    width: 80vw;
  }
  .landergen--female-circle--img {
    top: 40px;
    padding-left: 0px;
    left: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto; 
    width: auto; 
    max-width: 300px;
    max-height: 300px;
    max-width: 84vw;
  }
  .landergen-middle--slogan-header02 {
    position: relative;
    top: 610px;
    left: 0px;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
  .landergen-middle--slogan-sentences02 {
    top: 20px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw;
  }
  .landergen--match-sample--img {
    top: 40px;
    left: 0px;
    padding-left: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70vw;
    max-width: 300px;
  }
  .landergen-middle--download {
    top: 1050px;
    padding-left: 0vw;
    left: -2vw;
    font-size: 24px;
    line-height: 47px;
  }
  .landergen-middle--download--button {
    padding-left: 80px;
    left: 0vw;
    width: 200px;
  }
//------------------------------------------------------------------------------------------------
  .landergen-bottom {
    //height: 1240px;
    top: 1700px;
    height: 1580px;
    width: auto;
  }
  .landergen-bottom--sales-pitch {
    top: 60px;
    left: 5px;
    font-size: 24px;
    line-height: 32px;
    width: 290px;
  }
  .landergen-bottom--sales-header01 {
    top: 160px;
    left: 0vw;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
  .landergen-bottom--sales-sentences01 {
    top: 20px;
    left: 0px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw;
  }
  .landergen--refined-searches--img {
    top: 50px;
    left: -20px;
    padding-left: 0vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: auto;
    max-width: 83vw;
    max-height: auto;
  }
  .landergen-bottom--sales-header02 {
    padding-top: 65px;
    position: relative;
    top: 640px;
    left: 2vw;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
  .landergen-bottom--sales-sentences02 {
    top: 20px;
    left: 0px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw;
  }
  .landergen--bottom-phone--img {
    position: relative;
    padding-top: 400px;
    left: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50vw;
  }
//------------------------------------------------------------------------------------------------
  .landergen-footer {
    top: 3762px;
    height: 500px;
    z-index: -1;
  }
  .landergen-footer--storeinfo {
    padding-top: 70px;
    left: 1vw;
    font-size: 24px;
    line-height: 34px;
    width: 290px;
  }
  .landergen-footer--storeinfo--button {
    top: -20px;
    width: 200px;
    margin-left: -28vw;
  }
}