@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700);
@import url(https://fonts.googleapis.com/css?family=PT%20Serif:400,600,700);
.landergen {
  height: 642px;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: relative; }
  .landergen--slogan {
    box-sizing: content-box;
    height: calc(100% - 100px);
    color: #0b0f72;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: bold;
    left: 15vw;
    padding-left: 32vw;
    padding-top: 130px;
    letter-spacing: -1.39px;
    line-height: 47px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 80vw;
    max-width: 60vw;
    justify-content: left;
    text-align: left; }
    .landergen--slogan--store-button {
      padding-top: 10px;
      padding-left: 36vw;
      width: 100px;
      text-align: center;
      position: relative; }
  .landergen::before {
    background: -webkit-linear-gradient(347.24deg, #ffffff 0%, rgba(255, 255, 255, 0) 50%), url("/images/nose2nose-bw02-unsplash.jpeg");
    background: linear-gradient(102.76deg, #ffffff 0%, rgba(255, 255, 255, 0) 50%), url("/images/nose2nose-bw02-unsplash.jpeg");
    content: "";
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    will-change: transform;
    z-index: -1;
    width: 100%;
    height: 104%;
    opacity: 0.15; }

.landergen--logo-color--img {
  padding-top: 38px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 22vw; }

.landergen--phone-hand {
  position: absolute;
  top: calc(26% + 5px);
  z-index: 4; }

.landergen--phone-hand--img {
  max-width: 34vw; }

.landergen-middle {
  background: -webkit-radial-gradient(circle, #E32B6F 0%, #630B6F 100%);
  background: radial-gradient(circle, #E32B6F 0%, #630B6F 100%);
  top: 642px;
  height: 1280px;
  width: 100%;
  position: absolute; }
  .landergen-middle--slogan-header01 {
    position: relative;
    top: 208px;
    padding-left: 6vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 40px; }
  .landergen-middle--slogan-sentences01 {
    position: relative;
    box-sizing: content-box;
    color: #FFFFFF;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 48vw;
    max-width: 460px;
    padding-top: 10px; }
  .landergen-middle--slogan-header02 {
    position: relative;
    top: 520px;
    left: 5vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 40px; }
  .landergen-middle--slogan-sentences02 {
    padding-top: 10px;
    position: relative;
    box-sizing: content-box;
    color: #FFFFFF;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 46vw;
    max-width: 480px; }
  .landergen-middle--download {
    position: relative;
    top: 766px;
    height: 47px;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 36px;
    text-align: center;
    box-sizing: content-box;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 350px;
    margin-left: auto;
    margin-right: auto; }
    .landergen-middle--download--button {
      padding-left: 80px; }

.landergen--female-circle--img {
  top: -320px;
  padding-left: 48vw;
  position: relative;
  max-width: 40vw; }

.landergen--match-sample--img {
  top: -220px;
  left: 40vw;
  padding-left: 10vw;
  position: relative;
  width: 40vw;
  max-width: 400px; }

.landergen-bottom {
  position: relative;
  height: 1320px;
  width: 100%;
  top: 1460px;
  background: -webkit-radial-gradient(circle, #1B1FD2 0%, #101289 100%);
  background: radial-gradient(circle, #1B1FD2 0%, #101289 100%); }
  .landergen-bottom--sales-pitch {
    position: relative;
    color: #ffffff;
    top: 120px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: content-box;
    height: 108px;
    font-family: Montserrat;
    font-size: 36px;
    text-align: center;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 92vw; }
  .landergen-bottom--sales-header01 {
    position: relative;
    color: #ffffff;
    top: 330px;
    padding-left: 5vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    max-width: 540px; }
  .landergen-bottom--sales-sentences01 {
    position: relative;
    color: #ffffff;
    box-sizing: content-box;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 46vw;
    max-width: 460px;
    padding-top: 10px; }
  .landergen-bottom--sales-header02 {
    position: relative;
    color: #ffffff;
    top: 670px;
    padding-left: 5vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    max-width: 460px; }
  .landergen-bottom--sales-sentences02 {
    position: relative;
    color: #ffffff;
    box-sizing: content-box;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 48vw;
    max-width: 460px;
    padding-top: 10px; }

.landergen--refined-searches--img {
  top: -280px;
  left: 40vw;
  padding-left: 10vw;
  position: relative;
  max-width: 38vw; }

.landergen--bottom-phone--img {
  top: 420px;
  left: 60vw;
  position: relative;
  max-width: 30vw;
  z-index: +1; }

.landergen-footer {
  background-color: #FFFFFF;
  top: 3082px;
  height: 500px;
  width: 100%;
  position: absolute; }
  .landergen-footer--storeinfo {
    position: relative;
    top: 180px;
    height: 47px;
    color: #0b0f72;
    font-family: Montserrat;
    font-size: 36px;
    text-align: center;
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 98%; }
    .landergen-footer--storeinfo--button {
      position: relative;
      padding-top: 32px;
      padding-left: 36vw;
      justify-content: center;
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .landergen-footer::before {
    background: -webkit-linear-gradient(347.24deg, #ffffff 0%, rgba(255, 255, 255, 0) 50%), url("/images/smile-horiz-matheus-lira-1172433-unsplash.jpg");
    background: linear-gradient(102.76deg, #ffffff 0%, rgba(255, 255, 255, 0) 50%), url("/images/smile-horiz-matheus-lira-1172433-unsplash.jpg");
    content: "";
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    will-change: transform;
    z-index: -1;
    width: 100%;
    height: 104%;
    opacity: 0.15; }

/* start of phone styles */
@media screen and (max-width: 640px) {
  .landergen {
    height: 800px;
    display: block;
    padding-left: 0px; }
  .landerger--logo-color--img {
    padding-left: 0px;
    padding-top: 25px;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 50vw; }
  .landergen--slogan {
    width: auto;
    top: 30px;
    padding-left: 0px;
    left: 0px;
    padding-top: 30px;
    display: block;
    font-size: 19px;
    text-align: center;
    letter-spacing: -1.18px;
    line-height: 34px;
    max-width: 80%; }
  .landergen--slogan--store-button {
    top: 40px;
    right: 35vw;
    width: 200px;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .landergen--phone-hand {
    height: auto;
    width: auto;
    max-width: 300px;
    max-height: 300px;
    z-index: 4;
    position: relative;
    top: 100px;
    right: 25vw; }
  .landergen--phone-hand--img {
    max-width: 200vw;
    width: 128%; }
  .landergen-middle {
    top: 800px;
    height: 1500px; }
  .landergen-middle--slogan-header01 {
    position: relative;
    padding-left: 0px;
    top: 140px;
    left: 0px;
    font-size: 24px;
    line-height: 34px;
    max-width: 400px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto; }
  .landergen-middle--slogan-sentences01 {
    top: 20px;
    font-size: 18px;
    line-height: 27px;
    max-width: 400px;
    max-height: 300px;
    width: 80vw; }
  .landergen--female-circle--img {
    top: 40px;
    padding-left: 0px;
    left: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: auto;
    max-width: 300px;
    max-height: 300px;
    max-width: 84vw; }
  .landergen-middle--slogan-header02 {
    position: relative;
    top: 610px;
    left: 0px;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto; }
  .landergen-middle--slogan-sentences02 {
    top: 20px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw; }
  .landergen--match-sample--img {
    top: 40px;
    left: 0px;
    padding-left: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70vw;
    max-width: 300px; }
  .landergen-middle--download {
    top: 1050px;
    padding-left: 0vw;
    left: -2vw;
    font-size: 24px;
    line-height: 47px; }
  .landergen-middle--download--button {
    padding-left: 80px;
    left: 0vw;
    width: 200px; }
  .landergen-bottom {
    top: 1700px;
    height: 1580px;
    width: auto; }
  .landergen-bottom--sales-pitch {
    top: 60px;
    left: 5px;
    font-size: 24px;
    line-height: 32px;
    width: 290px; }
  .landergen-bottom--sales-header01 {
    top: 160px;
    left: 0vw;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto; }
  .landergen-bottom--sales-sentences01 {
    top: 20px;
    left: 0px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw; }
  .landergen--refined-searches--img {
    top: 50px;
    left: -20px;
    padding-left: 0vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: auto;
    max-width: 83vw;
    max-height: auto; }
  .landergen-bottom--sales-header02 {
    padding-top: 65px;
    position: relative;
    top: 640px;
    left: 2vw;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto; }
  .landergen-bottom--sales-sentences02 {
    top: 20px;
    left: 0px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw; }
  .landergen--bottom-phone--img {
    position: relative;
    padding-top: 400px;
    left: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50vw; }
  .landergen-footer {
    top: 3762px;
    height: 500px;
    z-index: -1; }
  .landergen-footer--storeinfo {
    padding-top: 70px;
    left: 1vw;
    font-size: 24px;
    line-height: 34px;
    width: 290px; }
  .landergen-footer--storeinfo--button {
    top: -20px;
    width: 200px;
    margin-left: -28vw; } }

.store-button--logo {
  font-size: 25px;
  margin-right: 6px;
  display: inline-block;
  top: 2px;
  position: relative; }

.store-button {
  border-radius: 5px;
  position: relative;
  top: 10px;
  width: 200px;
  padding: 5px 0px;
  background-color: #ee286a;
  /* display: inline; */ }
  .store-button a {
    color: #fff;
    font-size: 18px;
    text-decoration: none; }

.tos_p {
  background-color: #e9e5e5ef;
  position: relative;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  top: calc(100% - 100px);
  padding-left: 33%; }
  .tos_p--links {
    font-size: 10px;
    font-weight: bold;
    flex-direction: row;
    float: left;
    color: #66666666;
    font-family: Montserrat;
    min-width: 50vw;
    justify-content: center;
    padding: 10px;
    /* unvisited link */
    /* mouse over link */ }
    .tos_p--links--centered {
      margin: 10px auto; }
    .tos_p--links a:link, .tos_p--links a:visited, .tos_p--links a:active {
      color: #66666666;
      text-decoration: underline; }
    .tos_p--links a:hover {
      color: hotpink;
      text-decoration: none; }
  .tos_p::before {
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    content: "";
    height: 104%;
    position: absolute;
    top: 0;
    width: 100%;
    will-change: transform;
    z-index: -1;
    opacity: 0.15; }

@media screen and (max-width: 640px) {
  /* start of phone styles */
  .tos_p {
    height: auto; }
  .tos_p--links {
    width: auto;
    display: block; } }

.woman {
  height: 642px;
  width: 100%;
  background: -webkit-radial-gradient(circle, #E32B6F 0%, #630B6F 100%);
  background: radial-gradient(circle, #E32B6F 0%, #630B6F 100%); }
  .woman--slogan {
    box-sizing: content-box;
    height: calc(100% - 100px);
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: bold;
    left: 15vw;
    padding-left: 22vw;
    padding-top: 212px;
    letter-spacing: -1.39px;
    line-height: 47px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 48vw;
    max-width: 43%;
    justify-content: center;
    text-align: center; }
    .woman--slogan--store-button {
      padding-top: 10px;
      left: 4vw;
      width: 100px;
      text-align: center;
      position: relative;
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .woman::before {
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    content: "";
    height: 104%;
    position: absolute;
    top: 0;
    width: 100%;
    will-change: transform;
    z-index: -1;
    opacity: 0.15; }

.logo-white--img {
  padding-top: 38px;
  padding-left: 42vw;
  position: absolute; }

.woman--phone-hand {
  position: absolute;
  top: calc(26% + 5px);
  z-index: 4; }

.woman--phone-hand--img {
  max-width: 34vw; }

.woman-middle {
  background-color: #FFFFFF;
  top: 642px;
  height: 1280px;
  width: 100%;
  position: absolute; }
  .woman-middle--slogan-header01 {
    position: relative;
    top: 208px;
    padding-left: 6vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    color: #0b0f72;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 40px; }
  .woman-middle--slogan-sentences01 {
    position: relative;
    box-sizing: content-box;
    color: #464445;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 48vw;
    max-width: 460px; }
  .woman-middle--slogan-header02 {
    position: relative;
    top: 520px;
    left: 45vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    color: #0b0f72;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 40px; }
  .woman-middle--slogan-sentences02 {
    position: relative;
    box-sizing: content-box;
    color: #464445;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 46vw;
    max-width: 480px; }
  .woman-middle--download {
    position: relative;
    top: 766px;
    height: 47px;
    color: #0b0f72;
    font-family: Montserrat;
    font-size: 36px;
    text-align: center;
    box-sizing: content-box;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 350px;
    margin-left: auto;
    margin-right: auto; }
    .woman-middle--download--button {
      padding-left: 80px; }

.woman--match-bubbles--img {
  top: -210px;
  padding-left: 48vw;
  position: relative;
  max-width: 42vw; }

.woman--money-phone--img {
  top: -220px;
  left: -50vw;
  padding-left: 10vw;
  position: relative;
  max-width: 28vw; }

.woman-bottom {
  position: relative;
  height: 1320px;
  width: 100%;
  top: 1460px;
  background: -webkit-radial-gradient(circle, #E32B6F 0%, #630B6F 100%);
  background: radial-gradient(circle, #E32B6F 0%, #630B6F 100%); }
  .woman-bottom--sales-pitch {
    position: relative;
    color: #ffffff;
    top: 120px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: content-box;
    height: 108px;
    font-family: Montserrat;
    font-size: 36px;
    text-align: center;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 510px; }
  .woman-bottom--sales-header01 {
    position: relative;
    color: #ffffff;
    top: 330px;
    padding-left: 10vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    max-width: 540px; }
  .woman-bottom--sales-sentences01 {
    position: relative;
    color: #ffffff;
    box-sizing: content-box;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 46vw;
    max-width: 460px;
    padding-top: 10px; }
  .woman-bottom--sales-header02 {
    position: relative;
    color: #ffffff;
    top: 670px;
    left: 50vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    max-width: 460px; }
  .woman-bottom--sales-sentences02 {
    position: relative;
    color: #ffffff;
    box-sizing: content-box;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 48vw;
    max-width: 460px;
    padding-top: 10px; }

.woman--match-sample--img {
  top: -240px;
  padding-left: 47vw;
  position: relative;
  max-width: 40vw; }

.woman-bottom-phone--img {
  top: 480px;
  left: 10vw;
  position: relative;
  max-width: 30vw;
  z-index: +1; }

.woman-footer {
  background-color: #FFFFFF;
  top: 3082px;
  height: 500px;
  width: 100%;
  position: absolute; }
  .woman-footer--storeinfo {
    position: relative;
    top: 180px;
    height: 47px;
    color: #0b0f72;
    font-family: Montserrat;
    font-size: 36px;
    text-align: center;
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 98%; }
    .woman-footer--storeinfo--button {
      position: relative;
      padding-top: 32px;
      padding-left: 36vw;
      justify-content: center;
      display: block;
      margin-left: auto;
      margin-right: auto; }

/* start of phone styles */
@media screen and (max-width: 640px) {
  .woman {
    height: 800px;
    display: block;
    padding-left: 0px; }
  .logo-white--img {
    padding-left: 0px;
    padding-top: 25px;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .woman--slogan {
    width: auto;
    top: 30px;
    padding-left: 0px;
    left: 0px;
    padding-top: 30px;
    display: block;
    font-size: 19px;
    text-align: center;
    letter-spacing: -1.18px;
    line-height: 34px;
    max-width: 80%; }
  .woman--slogan--store-button {
    top: 40px;
    left: 0vw;
    width: 200px; }
  .woman--phone-hand {
    height: auto;
    width: auto;
    max-width: 300px;
    max-height: 300px;
    z-index: 4;
    position: relative;
    top: 100px;
    right: 25vw; }
  .woman--phone-hand--img {
    max-width: 200vw;
    width: 128%; }
  .woman-middle {
    top: 800px;
    height: 1500px; }
  .woman-middle--slogan-header01 {
    position: relative;
    padding-left: 0px;
    top: 140px;
    left: 0px;
    font-size: 24px;
    line-height: 34px;
    max-width: 400px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto; }
  .woman-middle--slogan-sentences01 {
    top: 20px;
    font-size: 18px;
    line-height: 27px;
    max-width: 400px;
    max-height: 300px;
    width: 80vw; }
  .woman--match-bubbles--img {
    top: 40px;
    padding-left: 0px;
    left: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: auto;
    max-width: 300px;
    max-height: 300px;
    max-width: 84vw; }
  .woman-middle--slogan-header02 {
    position: relative;
    top: 610px;
    left: 0px;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto; }
  .woman-middle--slogan-sentences02 {
    top: 20px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw; }
  .woman--money-phone--img {
    top: 40px;
    left: 0px;
    padding-left: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50vw;
    max-height: auto; }
  .woman-middle--download {
    top: 1070px;
    padding-left: 0vw;
    left: -2vw;
    font-size: 24px;
    line-height: 47px; }
  .woman-middle--download--button {
    padding-left: 80px;
    left: 0vw;
    width: 200px; }
  .woman-bottom {
    top: 1700px;
    height: 1580px;
    width: auto; }
  .woman-bottom--sales-pitch {
    top: 60px;
    left: 5px;
    font-size: 24px;
    line-height: 32px;
    width: 290px; }
  .woman-bottom--sales-header01 {
    top: 160px;
    left: 0vw;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto; }
  .woman-bottom--sales-sentences01 {
    top: 20px;
    left: 0px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw; }
  .woman--match-sample--img {
    top: 50px;
    left: -30px;
    padding-left: 0vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: auto;
    max-width: 64vw;
    max-height: auto; }
  .woman-bottom--sales-header02 {
    padding-top: 65px;
    position: relative;
    top: 640px;
    left: 2vw;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto; }
  .woman-bottom--sales-sentences02 {
    top: 20px;
    left: 0px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw; }
  .woman-bottom-phone--img {
    padding-top: 390px;
    left: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50vw; }
  .woman-footer {
    top: 3762px;
    height: 500px;
    z-index: -1; }
  .woman-footer--storeinfo {
    padding-top: 70px;
    left: 1vw;
    font-size: 24px;
    line-height: 34px;
    width: 290px; }
  .woman-footer--storeinfo--button {
    top: -20px;
    width: 200px;
    margin-left: -28vw; } }

.men {
  height: 642px;
  width: 100%;
  background: -webkit-radial-gradient(circle, #1B1FD2 0%, #101289 100%);
  background: radial-gradient(circle, #1B1FD2 0%, #101289 100%); }
  .men--slogan {
    box-sizing: content-box;
    height: calc(100% - 100px);
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 35px;
    font-weight: bold;
    left: 15vw;
    padding-top: 212px;
    padding-left: 22vw;
    letter-spacing: -1.39px;
    line-height: 47px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 48vw;
    max-width: 43%;
    justify-content: center;
    text-align: center; }
    .men--slogan--store-button {
      padding-top: 10px;
      left: 4vw;
      width: 100px;
      text-align: center;
      position: relative;
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .men::before {
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    content: "";
    height: 104%;
    position: absolute;
    top: 0;
    width: 100%;
    will-change: transform;
    z-index: -1;
    opacity: 0.15; }

.logo-white--img {
  padding-top: 38px;
  padding-left: 42vw;
  position: absolute; }

.men-phone-hand {
  position: absolute;
  top: calc(26% + 5px);
  z-index: 4; }

.men-phone-hand--img {
  max-width: 34vw; }

.men-middle {
  background-color: #FFFFFF;
  top: 642px;
  height: 1240px;
  width: 100%;
  position: absolute; }
  .men-middle--slogan-header01 {
    position: relative;
    top: 208px;
    padding-left: 6vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    color: #0b0f72;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 40px; }
  .men-middle--slogan-sentences01 {
    position: relative;
    box-sizing: content-box;
    color: #464445;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 48vw;
    max-width: 460px; }
  .men-middle--slogan-header02 {
    position: relative;
    top: 490px;
    left: 45vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    color: #0b0f72;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    line-height: 40px; }
  .men-middle--slogan-sentences02 {
    position: relative;
    box-sizing: content-box;
    color: #464445;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 48vw;
    max-width: 480px; }
  .men-middle--download {
    position: relative;
    top: -110px;
    left: -20vw;
    height: 47px;
    color: #0b0f72;
    font-family: Montserrat;
    font-size: 36px;
    text-align: center;
    box-sizing: content-box;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 350px;
    margin-left: auto;
    margin-right: auto; }
    .men-middle--download--button {
      padding-left: 80px; }

.men-match-sample--img {
  top: -240px;
  padding-left: 47vw;
  position: relative;
  max-width: 40vw; }

.men-conversation--img {
  top: -200px;
  left: -45vw;
  position: relative;
  max-width: 42vw; }

.men-bottom {
  position: relative;
  height: 1290px;
  width: 100%;
  top: 1260px;
  background: -webkit-radial-gradient(circle, #1B1FD2 0%, #101289 100%);
  background: radial-gradient(circle, #1B1FD2 0%, #101289 100%); }
  .men-bottom--sales-pitch {
    position: relative;
    color: #ffffff;
    top: 120px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: content-box;
    height: 108px;
    font-family: Montserrat;
    font-size: 36px;
    text-align: center;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 510px; }
  .men-bottom--sales-header01 {
    position: relative;
    color: #ffffff;
    top: 350px;
    padding-left: 6vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    max-width: 460px; }
  .men-bottom--sales-sentences01 {
    position: relative;
    color: #ffffff;
    box-sizing: content-box;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 48vw;
    max-width: 460px; }
  .men-bottom--sales-header02 {
    position: relative;
    color: #ffffff;
    top: 630px;
    left: 45vw;
    box-sizing: content-box;
    height: 108px;
    width: 47%;
    font-family: Montserrat;
    font-size: 28px;
    font-weight: bold;
    letter-spacing: -1px;
    max-width: 460px; }
  .men-bottom--sales-sentences02 {
    position: relative;
    color: #ffffff;
    box-sizing: content-box;
    font-family: "PT Serif";
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    width: 48vw;
    max-width: 460px;
    padding-top: 10px; }

.men-match-ring--img {
  top: -310px;
  padding-left: 50vw;
  position: relative;
  max-width: 42vw; }

.men-bottom-phone--img {
  top: 480px;
  left: 10vw;
  position: relative;
  max-width: 30vw;
  z-index: +1; }

.men-footer {
  background-color: #FFFFFF;
  top: 2950px;
  height: 600px;
  width: 100%;
  position: absolute; }
  .men-footer--storeinfo {
    position: relative;
    top: 180px;
    height: 47px;
    color: #0b0f72;
    font-family: Montserrat;
    font-size: 36px;
    text-align: center;
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    letter-spacing: -1.38px;
    line-height: 47px;
    width: 98%; }
    .men-footer--storeinfo--button {
      position: relative;
      padding-top: 32px;
      padding-left: 36vw;
      justify-content: center;
      display: block;
      margin-left: auto;
      margin-right: auto; }

/* start of phone styles */
@media screen and (max-width: 640px) {
  .men {
    height: 800px;
    display: block;
    padding-left: 0px; }
  .logo-white--img {
    padding-left: 0px;
    padding-top: 25px;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .men--slogan {
    width: auto;
    top: 30px;
    padding-left: 0px;
    left: 0px;
    padding-top: 30px;
    display: block;
    font-size: 19px;
    text-align: center;
    letter-spacing: -1.18px;
    line-height: 34px;
    max-width: 80%; }
  .men--slogan--store-button {
    top: 40px;
    left: 0vw;
    width: 200px; }
  .men-phone-hand {
    height: auto;
    width: auto;
    max-width: 300px;
    max-height: 300px;
    z-index: 4;
    position: relative;
    top: 100px;
    right: 20vw; }
  .men-phone-hand--img {
    max-width: 200vw;
    width: 128%; }
  .men-middle {
    top: 800px;
    height: 1650px; }
  .men-middle--slogan-header01 {
    position: relative;
    padding-left: 0px;
    top: 140px;
    left: 0px;
    font-size: 24px;
    line-height: 34px;
    max-width: 400px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto; }
  .men-middle--slogan-sentences01 {
    top: 20px;
    font-size: 18px;
    line-height: 27px;
    max-width: 400px;
    max-height: 300px;
    width: 80vw; }
  .men-match-sample--img {
    top: 40px;
    padding-left: 0vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: auto;
    max-width: 300px;
    max-height: 300px;
    max-width: 84vw; }
  .men-middle--slogan-header02 {
    position: relative;
    top: 610px;
    left: 0px;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto; }
  .men-middle--slogan-sentences02 {
    top: 20px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw; }
  .men-conversation--img {
    position: relative;
    padding-top: 300px;
    padding-left: 44vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 84vw; }
  .men-middle--download {
    padding-left: 0vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    left: -15vw;
    font-size: 24px;
    line-height: 47px; }
  .men-middle--download--button {
    left: 0vw;
    width: 200px; }
  .men-bottom {
    top: 1800px;
    height: 1670px;
    width: auto; }
  .men-bottom--sales-pitch {
    top: 60px;
    left: 5px;
    font-size: 24px;
    line-height: 32px;
    width: 290px; }
  .men-bottom--sales-header01 {
    top: 160px;
    left: 0vw;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto; }
  .men-bottom--sales-sentences01 {
    top: 20px;
    left: 0px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw; }
  .men-match-ring--img {
    top: 50px;
    left: -30px;
    padding-left: 0vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: auto;
    max-width: 320px;
    max-height: 320px;
    max-width: 84vw; }
  .men-bottom--sales-header02 {
    top: 720px;
    left: 2vw;
    font-size: 24px;
    line-height: 34px;
    width: 80vw;
    margin-left: auto;
    margin-right: auto; }
  .men-bottom--sales-sentences02 {
    top: 20px;
    left: 0px;
    font-size: 18px;
    line-height: 27px;
    width: 80vw; }
  .men-bottom-phone--img {
    padding-top: 550px;
    left: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 50vw; }
  .men-footer {
    top: 4000px;
    height: 550px;
    z-index: -1; }
  .men-footer--storeinfo {
    padding-top: 40px;
    left: 0vw;
    font-size: 21px;
    line-height: 34px;
    width: 70%; }
  .men-footer--storeinfo--button {
    top: -20px;
    width: 200px;
    padding-left: 0vw;
    display: block;
    margin-left: auto;
    margin-right: auto; } }

.ToSmain {
  background-color: #FFFFFF;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 30px;
  padding-bottom: 60px;
  font-family: "bebas";
  display: "block";
  font-size: 14px;
  color: #333;
  background-color: "white";
  word-break: "break-word";
  justify-content: left; }
  .ToSmain h1 {
    font-size: 36px;
    font-weight: bold; }
  .ToSmain h2 {
    font-size: 18px;
    font-weight: bold; }
  .ToSmain h3 {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold; }
  .ToSmain--text {
    font-family: "courier new";
    line-height: 16px;
    word-spacing: 0.1em; }
    .ToSmain--text-sub {
      margin-left: 10px;
      word-spacing: 0.1em;
      margin-right: 20px; }
    .ToSmain--text-boldall {
      font-weight: bold;
      word-spacing: 0.1em; }
  .ToSmain h4 {
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    margin-left: 10px;
    margin-top: 8px;
    margin-bottom: 0px;
    word-spacing: 0.1em; }
  .ToSmain--indexlinks {
    font-size: 14px;
    word-spacing: 0.1em;
    font-weight: bold;
    /* unvisited link */
    /* mouse over link */ }
    .ToSmain--indexlinks a:link, .ToSmain--indexlinks a:visited, .ToSmain--indexlinks a:active {
      color: blue;
      text-decoration: underline; }
    .ToSmain--indexlinks a:hover {
      color: hotpink;
      text-decoration: none; }
  .ToSmain--linebreak {
    line-height: 36px;
    font-weight: bold;
    font-family: "courier new"; }
    .ToSmain--linebreak hr {
      color: #333;
      background-color: #333;
      height: 5px; }

.logo-black--img {
  max-width: 15%;
  height: auto; }

.privacymain {
  background-color: #FFFFFF;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 30px;
  padding-bottom: 60px;
  font-family: "bebas";
  display: "block";
  font-size: 14px;
  color: #333;
  background-color: "white";
  word-break: "break-word";
  justify-content: left; }
  .privacymain h1 {
    font-size: 36px;
    font-weight: bold; }
  .privacymain h2 {
    font-size: 18px;
    font-weight: bold; }
  .privacymain h3 {
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold; }
  .privacymain--text {
    font-family: "courier new";
    line-height: 16px;
    word-spacing: 0.1em; }
    .privacymain--text-sub {
      margin-left: 10px;
      margin-right: 20px;
      word-spacing: 0.1em; }
    .privacymain--text-boldall {
      font-weight: bold;
      word-spacing: 0.1em; }
  .privacymain h4 {
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    margin-left: 10px;
    margin-top: 8px;
    margin-bottom: 0px;
    word-spacing: 0.1em; }
  .privacymain--indexlinks {
    font-size: 14px;
    word-spacing: 0.1em;
    font-weight: bold;
    /* unvisited link */
    /* mouse over link */ }
    .privacymain--indexlinks a:link, .privacymain--indexlinks a:visited, .privacymain--indexlinks a:active {
      color: blue;
      text-decoration: underline; }
    .privacymain--indexlinks a:hover {
      color: hotpink;
      text-decoration: none; }
  .privacymain--linebreak {
    line-height: 36px;
    font-weight: bold;
    font-family: "courier new"; }
    .privacymain--linebreak hr {
      color: #333;
      background-color: #333;
      height: 5px; }

.logo-black--img {
  max-width: 15%;
  height: auto; }

.keith--store-button {
  position: relative;
  display: block; }

.keith-ad-image {
  position: relative; }

.keith-ad-button {
  top: 309px;
  left: 224px;
  position: absolute; }


.App {
  font-family: sans-serif;
  text-align: center;
  margin: 0 0;
  padding: 0px;
}

html {
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

